<template>
  <div class="wrap">
    <TablePage v-model="options" @handleEvent="handleEvent"></TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { listOrder } from '@/api/shop/bill/waste' //门店
export default {
  name: "orderQuery",
  components: { TablePage },
  data() {
    return {
      payTypes: [], // 支付方式
      options: {
        summary: "",
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 10
        },
        getListApi: listOrder,
        body: {},
        title: "裱花订单查询",
        rowKey: "billNo",
        search: [
          {
            label: "日期",
            type: "daterange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: "daterange",
            type: "timeRange",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth", "quarter","lastQuarter","year"]
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
        ],
        columns: [
          { prop: "userId", label: "序号", minWidth: "80" },
          { prop: "billNo", label: "单据编号", minWidth: "155" },
          { prop: "billStatusName", label: "单据状态", minWidth: "80" },
          { prop: "outStoreNo", label: "仓库编号", minWidth: "110" },
          { prop: "outStoreName", label: "仓库名称", minWidth: "110" },
          { prop: "billMoney", label: "单据金额", minWidth: "80" },
          { prop: "auditBy", label: "审核人", minWidth: "90" },
          { prop: "auditTime", label: "审核日期", minWidth: "110" },
          { prop: "billRemark", label: "备注", minWidth: "90" },
          { prop: "createBy", label: "制单人", minWidth: "90" },
          { prop: "createTime", label: "制单日期", minWidth: "110" },
          { prop: "updateBy", label: "修改人", minWidth: "90" },
          { prop: "updateTime", label: "修改日期", minWidth: "110" },
        ],
        list: []
      }
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "getListed":
          break;
        default:
          break;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
</style>
    