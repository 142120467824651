var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap", attrs: { id: "wrap" } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingGoodsTable,
              expression: "loadingGoodsTable",
            },
          ],
          staticClass: "content",
        },
        [
          _c(
            "cardTitleCom",
            { staticClass: "cardDetail", attrs: { cardTitle: "原料明细" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "tableContent" },
                  [
                    _c(
                      "el-form",
                      { ref: "form", attrs: { rules: _vm.rules } },
                      [
                        _c(
                          "el-table",
                          {
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.tableDataGoods,
                              border: "",
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                            },
                            on: { "row-click": _vm.handleRowClick },
                          },
                          [
                            _c("el-table-column", {
                              attrs: { width: "50", align: "center" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-radio",
                                        {
                                          attrs: { label: scope.row },
                                          model: {
                                            value: _vm.radioItem,
                                            callback: function ($$v) {
                                              _vm.radioItem = $$v
                                            },
                                            expression: "radioItem",
                                          },
                                        },
                                        [_vm._v(_vm._s(""))]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                prop: "userId",
                                width: "60",
                                type: "index",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品编码",
                                align: "center",
                                prop: "goodsNo",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品名称",
                                align: "center",
                                prop: "goodsName",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "规格",
                                align: "center",
                                prop: "goodsSpec",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单位",
                                align: "center",
                                prop: "unitName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            {
              staticClass: "cardAvailable",
              attrs: { cardTitle: _vm.selectGoodName },
            },
            [
              _c(
                "template",
                { slot: "rightCardTitle" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "marR10",
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v("保存 ")]
                  ),
                ],
                1
              ),
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "tableContent" },
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.replaceLoading,
                            expression: "replaceLoading",
                          },
                        ],
                        attrs: {
                          "tooltip-effect": "dark",
                          data: _vm.tableDataReplace,
                          border: "",
                          "max-height": _vm.tableHeight,
                          height: _vm.tableHeight,
                        },
                        on: {
                          "cell-mouse-enter": _vm.cellMouseEnter,
                          "cell-mouse-leave": _vm.cellMouseLeave,
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: _vm.tableCellLabel,
                            width: "80",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.hoverRow
                                    ? _c("i", {
                                        staticClass:
                                          "el-icon-circle-plus operatePush",
                                        on: {
                                          click: function ($event) {
                                            return _vm.rowReplace(
                                              "push",
                                              scope.$index,
                                              scope.row.usableGoodsId
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  scope.row.hoverRow
                                    ? _c("i", {
                                        staticClass:
                                          "el-icon-remove operateDel",
                                        on: {
                                          click: function ($event) {
                                            return _vm.rowReplace(
                                              "del",
                                              scope.$index,
                                              scope.row.usableGoodsId
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  !scope.row.hoverRow
                                    ? _c("div", [
                                        _vm._v(_vm._s(scope.$index + 1)),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "替代商品编码",
                            align: "center",
                            prop: "usableGoodsNo",
                            width: "160",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-input",
                                    {
                                      attrs: { disabled: _vm.disabled },
                                      model: {
                                        value: scope.row.usableGoodsNo,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "usableGoodsNo",
                                            $$v
                                          )
                                        },
                                        expression: "scope.row.usableGoodsNo",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-more",
                                        attrs: { slot: "suffix" },
                                        on: { click: _vm.selectGoods },
                                        slot: "suffix",
                                      }),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "替代商品名称",
                            align: "center",
                            width: "120",
                            prop: "usableGoodsName",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "规格",
                            align: "center",
                            prop: "goodsSpec",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "单位",
                            align: "center",
                            prop: "usableGoodsUnitId",
                            width: "120",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-select",
                                    {
                                      model: {
                                        value: scope.row.usableGoodsUnitId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "usableGoodsUnitId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "scope.row.usableGoodsUnitId",
                                      },
                                    },
                                    _vm._l(scope.row.units, function (item) {
                                      return _c("el-option", {
                                        key: item.unitId,
                                        attrs: {
                                          label: item.unitName,
                                          value: item.unitId,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "isMain",
                            label: "主要替代",
                            width: "80",
                            align: "center",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-switch", {
                                    attrs: {
                                      "active-value": true,
                                      "inactive-value": false,
                                      disabled:
                                        scope.row.usableGoodsId == undefined,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.switchChange(
                                          $event,
                                          scope.$index
                                        )
                                      },
                                    },
                                    model: {
                                      value: scope.row.isMain,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "isMain", $$v)
                                      },
                                      expression: "scope.row.isMain",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "替代比例(%)",
                            align: "center",
                            prop: "exchangeScale",
                            width: "120",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    model: {
                                      value: scope.row.exchangeScale,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          scope.row,
                                          "exchangeScale",
                                          $$v
                                        )
                                      },
                                      expression: "scope.row.exchangeScale",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "备注",
                            align: "center",
                            prop: "remark",
                            width: "160",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    model: {
                                      value: scope.row.remark,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "remark", $$v)
                                      },
                                      expression: "scope.row.remark",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("Dialog", {
        ref: "tablePage",
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }