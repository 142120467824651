<template>
  <div class="wrap" v-loading="loading">
    <cardTitleCom cardTitle="搜索">
      <template slot="cardContent">
        <div class="navSearch">
          <!-- 高级搜索按钮 -->
          <seniorSearch v-model="queryParams" :getList="getList" @isShowHighCom="getIsShowHigh" />
          <div v-show="showHigh" class="searchCriteria">
            <div class="marR20">
              <span class="fS14MR10">单据编号</span>
              <el-input class="inputWidth marR15" placeholder="全部" v-model="queryParams.billNo" />
              <span class="fS14MR10">修改类型</span>
              <el-select class="inputWidth marR15" v-model="queryParams.optType" placeholder="全部" filterable clearable>
                <el-option label="配方原料替换" value="0"></el-option>
                <el-option label="配方新增子件" value="1"></el-option>
              </el-select>
              <span class="fS14MR10">单据状态</span>
              <el-select class="inputWidth marR15" v-model="queryParams.billStatus" placeholder="全部" filterable
                clearable>
                <el-option label="未审核" value="0"></el-option>
                <el-option label="已审核" value="2"></el-option>
                <el-option label="已删除" value="3"></el-option>
              </el-select>
              <span class="fS14MR10">创建人</span>
              <el-select class="inputWidth" v-model="queryParams.createEmployeeId" placeholder="全部" filterable
                clearable>
                <el-option v-for="item in employeeData" :key="item.employeeId" :label="item.employeeName"
                  :value="item.employeeId"></el-option>
              </el-select>
            </div>
          </div>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="单据信息">
      <template slot="cardContent">
        <div class="tableContent">
          <div class="tableBtn">
            <div>
              <el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
              <el-button @click="auditBill('审核')" size="mini" :disabled="multiple" type="success">审核</el-button>
              <el-button type="danger" icon="el-icon-delete" :disabled="multiple" @click="auditBill('删除')"
                size="mini">删除
              </el-button>
            </div>

            <!-- 右边盒子 -->
            <div>
              <el-button size="mini" class="marRBtn" icon="el-icon-refresh" @click="test($event)">刷新
              </el-button>
            </div>
          </div>
          <el-table v-loading="loadingTable" ref="multipleTable" tooltip-effect="dark" :data="tableData"
            @selection-change="handleSelectionChange" border max-height="590" height="590" @row-click="handleRowClick">
            <el-table-column type="selection" width="50" align="center" />
            <el-table-column label="序号" align="center" prop="userId" width="80" type="index" />
            <el-table-column show-overflow-tooltip label="单据编号" align="center" prop="billNo" width="155">
              <template slot-scope="scope">
                <el-link type="primary" @click="handleDetail(scope.row)" target="_blank">
                  {{ scope.row.billNo }}
                </el-link>
              </template>
            </el-table-column>
            <el-table-column label="单据时间" align="center" prop="billDate" width="155" />
            <el-table-column label="修改类型" align="center" prop="optType">
              <template slot-scope="scope">
                {{ scope.row.optType == 0 ? '配方原料替换' : '配方新增子件' }}
              </template>
            </el-table-column>
            <el-table-column label="单据状态" align="center" prop="billStatusName" />
            <el-table-column label="创建人" align="center" prop="createBy" />
            <el-table-column label="创建时间" align="center" prop="createTime" width="160" />
            <el-table-column label="修改人" align="center" prop="updateBy" />
            <el-table-column label="修改时间" align="center" prop="updateTime" width="160" />
            <el-table-column label="备注" align="center" prop="billRemark" />
          </el-table>
          <bottomPage v-model="queryParams" :getList="getList" :idsCom="ids" :totalCom="total"
            @selectAllCom="selectAll" />
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import bottomPage from '@/views/components/bottomPage' //底部分页
import { allEmployeeList } from '@/api/system/employee' //制单人
import {
  batcheditListAPI,
  batcheditUpdateStatusAPI
} from '@/api/produce/bom/batchedit' //批量修改
export default {
  name: 'batchedit',
  dicts: ['bill_status'],
  components: { bottomPage, cardTitleCom, seniorSearch },
  data() {
    return {
      // 遮罩层
      loading: false,
      //表格遮罩
      loadingTable: false,
      multiple: true, // 非单个禁用
      employeeData: [], //制单人框
      showHigh: false, //是否显示高级搜索
      icon: 'el-icon-caret-bottom', //高级搜索图标
      name: [], //选中数组的名称
      ids: [], // 选中数组
      billStatusZeroId: [], //选中数组的未审核的状态0
      billStatusTwoId: [], //选中数组的已审核的状态2
      billStatusThreeId: [], //选中数组的已删除的状态3
      total: 0, // 总条数
      tableData: [], //表格数据
      datetime: undefined, //单据查询时间
      queryParams: {
        pageNum: 1,
        pageSize: 12
      } //查询
    }
  },
  created() {
    //初始化全部的数据
    this.getInitializeDataAll()
  },
  methods: {
    //点击行选中
    handleRowClick(row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.multipleTable.toggleRowSelection(row)
    },
    //搜索按钮
    handleQuery() {
      this.queryParams.pageNum = 1
      this.getList()
    },
    //刷新按钮
    test(e) {
      let target = e.target
      if (target.nodeName == 'I' || target.nodeName == 'SPAN') {
        target = e.target.parentNode
      }
      target.blur()
      this.getList()
    },
    //初始化全部的数据
    async getInitializeDataAll() {
      this.loading = true
      //表格数据
      const res1 = await batcheditListAPI(this.queryParams)
      this.tableData = res1.rows
      this.total = res1.total
      //获取制单人
      const res2 = await allEmployeeList()
      this.employeeData = res2.data
      this.loading = false
    },
    /** 查询表格列表 */
    async getList() {
      this.loadingTable = true
      const { rows, total } = await batcheditListAPI(this.queryParams)
      this.tableData = rows
      this.total = total
      this.loadingTable = false
    },
    //是否显示高级搜索
    getIsShowHigh(value) {
      this.showHigh = value
    },
    //审核/删除/终止事件
    async auditBill(name) {
      try {
        await this.$confirm(`确定${name}该订单, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'success'
        })

        if (name === '审核') {
          if (this.billStatusZeroId.length === 0) {
            this.$message.error('勾选单据中没有未审核的单据哦~')
            return
          }
          const obj = {
            billIds: this.billStatusZeroId,
            billStatus: '2'
          }
          //发送审核api
          await batcheditUpdateStatusAPI(obj)
          // 弹出提示
          this.$message.success('审核成功')
        } else if (name === '删除') {
          if (this.billStatusThreeId.length === 0) {
            this.$message.error('勾选单据中没有未审核的单据哦~')
            return
          }
          const obj = {
            billIds: this.billStatusThreeId,
            billStatus: '3'
          }
          //发送删除api
          await batcheditUpdateStatusAPI(obj)
          // 弹出提示
          this.$message.success('删除成功')
        }
        await this.getList()
      } catch { }
    },
    //是否显示高级搜索
    showHighSearch() {
      this.icon =
        this.icon === 'el-icon-caret-bottom'
          ? 'el-icon-caret-top'
          : 'el-icon-caret-bottom'
      this.showHigh = !this.showHigh
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.billId) //单据id
      //筛选出来未审核的单据状态id0
      this.billStatusZeroId = selection
        .map(item => {
          if (item.billStatus == '0') {
            return item.billId
          }
        })
        .filter(ite => typeof ite !== 'undefined')
      //筛选出来已审核的单据状态id2
      this.billStatusTwoId = selection
        .map(item => {
          if (item.billStatus == '2') {
            return item.billId
          }
        })
        .filter(ite => typeof ite !== 'undefined')
      //筛选出来未删除的单据状态id3
      this.billStatusThreeId = selection
        .map(item => {
          if (item.billStatus != '3' && item.billStatus == '0') {
            return item.billId
          }
        })
        .filter(ite => typeof ite !== 'undefined')
      this.multiple = !selection.length
    },
    //点击全选所有数据
    async selectAll(value) {
      if (value) {
        this.loadingTable = true
        const res = await batcheditListAPI({
          ...this.queryParams,
          pageNum: 1,
          pageSize: this.total
        })
        //重新渲染表格
        this.tableData = res.rows
        //表格多选
        this.tableData.map(row => {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row, value)
          })
        })
        this.loadingTable = false
      } else {
        //取消全选
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection()
        })
        this.getList()
      }
    },
    //新增单据事件
    handleAdd() {
      this.$router.push({
        name: 'batcheditDetail'
      })
    },
    //跳转详情
    handleDetail(row) {
      const billId = row.billId
      this.$router.push({
        name: 'batcheditDetail',
        query: {
          billId: billId,
          type: 'Update'
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
//外面盒子
.wrap {
  padding: 6px 10px 6px 10px;
  background-color: #eaeaea;
  // 搜索
  .navSearch {
    padding: 10px 10px 10px 20px;
    //时间
    .radioGroup {
      margin-right: 50px;
    }
    //筛选条件
    .searchCriteria {
      padding-top: 10px;
    }
  }
  //配方批量修改表格
  .tableContent {
    margin: 6px 10px 0 10px;
    .tableBtn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      line-height: 50px;
      background-color: #f9f9fa;
      padding-left: 5px;
      margin-bottom: 6px;
      .closeBtn {
        color: #ffffff;
        background-color: #ced200;
        border: none;
      }
    }
  }
}
</style>
