var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wrap",
    },
    [
      _c(
        "cardTitleCom",
        { attrs: { cardTitle: "搜索" } },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "navSearch" },
              [
                _c("seniorSearch", {
                  attrs: { getList: _vm.getList },
                  on: { isShowHighCom: _vm.getIsShowHigh },
                  model: {
                    value: _vm.queryParams,
                    callback: function ($$v) {
                      _vm.queryParams = $$v
                    },
                    expression: "queryParams",
                  },
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showHigh,
                        expression: "showHigh",
                      },
                    ],
                    staticClass: "searchCriteria",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "marR20" },
                      [
                        _c("span", { staticClass: "fS14MR10" }, [
                          _vm._v("单据编号"),
                        ]),
                        _c("el-input", {
                          staticClass: "inputWidth marR15",
                          attrs: { placeholder: "全部" },
                          model: {
                            value: _vm.queryParams.billNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "billNo", $$v)
                            },
                            expression: "queryParams.billNo",
                          },
                        }),
                        _c("span", { staticClass: "fS14MR10" }, [
                          _vm._v("修改类型"),
                        ]),
                        _c(
                          "el-select",
                          {
                            staticClass: "inputWidth marR15",
                            attrs: {
                              placeholder: "全部",
                              filterable: "",
                              clearable: "",
                            },
                            model: {
                              value: _vm.queryParams.optType,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "optType", $$v)
                              },
                              expression: "queryParams.optType",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "配方原料替换", value: "0" },
                            }),
                            _c("el-option", {
                              attrs: { label: "配方新增子件", value: "1" },
                            }),
                          ],
                          1
                        ),
                        _c("span", { staticClass: "fS14MR10" }, [
                          _vm._v("单据状态"),
                        ]),
                        _c(
                          "el-select",
                          {
                            staticClass: "inputWidth marR15",
                            attrs: {
                              placeholder: "全部",
                              filterable: "",
                              clearable: "",
                            },
                            model: {
                              value: _vm.queryParams.billStatus,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "billStatus", $$v)
                              },
                              expression: "queryParams.billStatus",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "未审核", value: "0" },
                            }),
                            _c("el-option", {
                              attrs: { label: "已审核", value: "2" },
                            }),
                            _c("el-option", {
                              attrs: { label: "已删除", value: "3" },
                            }),
                          ],
                          1
                        ),
                        _c("span", { staticClass: "fS14MR10" }, [
                          _vm._v("创建人"),
                        ]),
                        _c(
                          "el-select",
                          {
                            staticClass: "inputWidth",
                            attrs: {
                              placeholder: "全部",
                              filterable: "",
                              clearable: "",
                            },
                            model: {
                              value: _vm.queryParams.createEmployeeId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryParams,
                                  "createEmployeeId",
                                  $$v
                                )
                              },
                              expression: "queryParams.createEmployeeId",
                            },
                          },
                          _vm._l(_vm.employeeData, function (item) {
                            return _c("el-option", {
                              key: item.employeeId,
                              attrs: {
                                label: item.employeeName,
                                value: item.employeeId,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "cardTitleCom",
        { attrs: { cardTitle: "单据信息" } },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "tableContent" },
              [
                _c("div", { staticClass: "tableBtn" }, [
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            icon: "el-icon-plus",
                            size: "mini",
                          },
                          on: { click: _vm.handleAdd },
                        },
                        [_vm._v("新增")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            disabled: _vm.multiple,
                            type: "success",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.auditBill("审核")
                            },
                          },
                        },
                        [_vm._v("审核")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "danger",
                            icon: "el-icon-delete",
                            disabled: _vm.multiple,
                            size: "mini",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.auditBill("删除")
                            },
                          },
                        },
                        [_vm._v("删除 ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "marRBtn",
                          attrs: { size: "mini", icon: "el-icon-refresh" },
                          on: {
                            click: function ($event) {
                              return _vm.test($event)
                            },
                          },
                        },
                        [_vm._v("刷新 ")]
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loadingTable,
                        expression: "loadingTable",
                      },
                    ],
                    ref: "multipleTable",
                    attrs: {
                      "tooltip-effect": "dark",
                      data: _vm.tableData,
                      border: "",
                      "max-height": "590",
                      height: "590",
                    },
                    on: {
                      "selection-change": _vm.handleSelectionChange,
                      "row-click": _vm.handleRowClick,
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "selection",
                        width: "50",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "序号",
                        align: "center",
                        prop: "userId",
                        width: "80",
                        type: "index",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        "show-overflow-tooltip": "",
                        label: "单据编号",
                        align: "center",
                        prop: "billNo",
                        width: "155",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-link",
                                {
                                  attrs: { type: "primary", target: "_blank" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDetail(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(scope.row.billNo) + " ")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "单据时间",
                        align: "center",
                        prop: "billDate",
                        width: "155",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "修改类型",
                        align: "center",
                        prop: "optType",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.optType == 0
                                      ? "配方原料替换"
                                      : "配方新增子件"
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "单据状态",
                        align: "center",
                        prop: "billStatusName",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "创建人",
                        align: "center",
                        prop: "createBy",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "创建时间",
                        align: "center",
                        prop: "createTime",
                        width: "160",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "修改人",
                        align: "center",
                        prop: "updateBy",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "修改时间",
                        align: "center",
                        prop: "updateTime",
                        width: "160",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "备注",
                        align: "center",
                        prop: "billRemark",
                      },
                    }),
                  ],
                  1
                ),
                _c("bottomPage", {
                  attrs: {
                    getList: _vm.getList,
                    idsCom: _vm.ids,
                    totalCom: _vm.total,
                  },
                  on: { selectAllCom: _vm.selectAll },
                  model: {
                    value: _vm.queryParams,
                    callback: function ($$v) {
                      _vm.queryParams = $$v
                    },
                    expression: "queryParams",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }