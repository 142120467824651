<template>
  <el-form ref="form" label-width="70px">
    <div class="wrap" id="wrap" v-loading="loading">
      <!-- 顶部按钮 -->
      <topOperatingButton
        :isSubmitAddBtn="false"
        :isAuditBillBtn="false"
        @submitForm="submitForm"
        @getQuit="getQuit"
        id="topOperatingButton"
      />
      <cardTitleCom cardTitle="查询" id="search-card">
        <template slot="cardContent">
          <div class="navSearch">
            <div class="marB10">
              <span class="fS14MR10">单据类型</span>
              <el-checkbox v-model="queryParams.isProduce" class="checkOrder"
                >生产计划单</el-checkbox
              >
              <el-checkbox v-model="queryParams.isShopDue" class="checkOrder"
                >门店预订单</el-checkbox
              >
              <el-checkbox
                v-model="queryParams.isShopMaterialOrder"
                class="checkOrder"
                >门店原料订货单</el-checkbox
              >
              <el-checkbox v-model="queryParams.isShopOrder" class="checkOrder"
                >门店订货单</el-checkbox
              >
              <el-checkbox v-model="queryParams.isSell" class="checkOrder"
                >销售订单</el-checkbox
              >
              <el-checkbox
                v-model="queryParams.isProductStorage"
                class="checkOrder"
                >产品入库单</el-checkbox
              >
              <el-checkbox
                v-model="queryParams.isProduceOtherOrder"
                class="checkOrder"
                >其他订货单</el-checkbox
              >
              <el-checkbox
                v-model="queryParams.isProduceOtherMaterialOrder"
                class="checkOrder"
                >其他原料订货单</el-checkbox
              >
            </div>
            <!-- 高级搜索按钮 -->
            <seniorSearch
              filterTimeStr="thisMonth"
              v-model="queryParams"
              :getList="getList"
              :refreshSearch="refreshQueryParams"
              @isShowHighCom="getIsShowHigh"
              :isInvokeList="false"
              :class="{ marB10: !showHigh }"
            />
            <!-- 第二行 -->
            <div v-show="showHigh" class="x-w marT10">
              <el-form-item
                label="到货日期"
                prop="datetimeArrival"
                class="marR10"
              >
                <el-date-picker
                  size="mini"
                  v-model="datetimeArrival"
                  type="datetimerange"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :default-time="['00:00:00', '23:59:59']"
                  value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="生产班次" prop="produceOrderIds">
                <SelectRemote
                  :multiple="true"
                  v-model="queryParams.produceOrderIds"
                  style="width: 250px"
                  :option="
                    $select({
                      key: 'listProduceOrder',
                      option: {
                        option: {
                          remoteBody: {
                            produceDeptId: queryParams.produceDeptId,
                          },
                        },
                      },
                    }).option
                  "
                />
              </el-form-item>
              <el-form-item label="客户" prop="customerIds">
                <SelectRemote
                  :multiple="true"
                  v-model="queryParams.customerIds"
                  style="width: 250px"
                  :option="$select({ key: 'listCustomer' }).option"
                />
              </el-form-item>
              <el-form-item label="订货仓库" prop="outStoreIds">
                <SelectRemote
                  :multiple="true"
                  v-model="queryParams.inStoreIds"
                  style="width: 250px"
                  :option="
                    $select({
                      key: 'listStore',
                    }).option
                  "
                />
              </el-form-item>
            </div>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="选择源单">
        <template slot="cardContent">
          <div class="tableContent">
            <el-table
              v-loading="loadingTable"
              ref="multipleTable"
              @row-click="handleRowClick"
              tooltip-effect="dark"
              :data="tableData"
              @selection-change="handleSelectionChange"
              border
              :max-height="tableHeight"
              :height="tableHeight"
            >
              <el-table-column type="selection" width="50" align="center" />
              <el-table-column
                label="序号"
                align="center"
                width="80"
                type="index"
              />
              <el-table-column
                show-overflow-tooltip
                label="单据编号"
                align="center"
                prop="billNo"
                width="180"
              />
              <el-table-column
                label="单据日期"
                align="center"
                prop="billDate"
                width="155"
              />
              <el-table-column
                label="生产工厂"
                align="center"
                prop="deptName"
                width="120"
              />
              <el-table-column
                label="生产组"
                align="center"
                prop="produceGroupName"
                width="120"
              />
              <el-table-column
                label="生产班次"
                align="center"
                prop="produceOrderName"
                width="120"
              />
              <el-table-column
                label="数量"
                align="center"
                prop="billQty"
                width="120"
              />
              <el-table-column
                label="金额"
                align="center"
                prop="billMoney"
                width="120"
              />
              <el-table-column
                label="订货仓库"
                align="center"
                prop="inStoreName"
                width="126"
              />
              <el-table-column
                label="到货日期"
                align="center"
                prop="arrivalDate"
                width="155"
              />
              <el-table-column
                show-overflow-tooltip
                label="客户编号"
                align="center"
                prop="customerNo"
                width="180"
              />
              <el-table-column
                show-overflow-tooltip
                label="客户名称"
                align="center"
                prop="customerName"
                width="120"
              />
              <el-table-column
                label="备注"
                align="center"
                prop="billRemark"
                width="155"
              />
            </el-table>
            <bottomPage
              v-model="queryParams"
              :getList="getList"
              :idsCom="selectionWizard"
              :totalCom="total"
              @selectAllCom="selectAll"
            />
          </div>
        </template>
      </cardTitleCom>
    </div>
  </el-form>
</template>

<script>
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import seniorSearch from "@/views/components/seniorSearch"; //高级搜索
import bottomPage from "@/views/components/bottomPage"; //底部分页
import {
  getSourceOrderData,
  getSourceOrderDetailData,
  findStockQtyAPI,
} from "@/api/produce/bill/storeDistribute"; //产品分货单
import topOperatingButton from "@/views/components/topOperatingButton"; //顶部按钮
import SelectRemote from "@/components/tablePage/select/select-remote/index.vue";
export default {
  name: "distributeImport",
  components: {
    bottomPage,
    cardTitleCom,
    seniorSearch,
    topOperatingButton,
    SelectRemote,
  },
  data() {
    return {
      datetimeArrival: undefined, //到货查询时间
      //原料、半成品、成品id
      goodsStoreObj: {},
      //部门信息框
      opendept: false,
      //自定义搜索框字段转换 (部门)
      normalizerdept: {
        Id: "deptId",
        No: "deptNo",
        Name: "deptName",
      },
      //生产班次信息框
      openProduceOrder: false,
      //生产班次列表
      produceOrderList: [],
      //自定义搜索框字段转换 (生产班次)
      normalizerproduceOrder: {
        Id: "produceOrderId",
        No: "produceOrderNo",
        Name: "produceOrderName",
      },
      //生产组信息框
      openProduceGroup: false,
      //生产组列表
      produceGroupList: [],
      //自定义搜索框字段转换 (生产组)
      normalizerProduceGroup: {
        Id: "produceGroupId",
        No: "produceGroupNo",
        Name: "produceGroupName",
      },
      loading: false,
      loadingTable: false,
      storeData: [], //仓库数据
      customerData: [], //客户
      showHigh: false, //是否显示高级搜索
      selectionWizard: [], // 选中数组
      total: 0, // 总条数
      tableData: [], //表格数据
      //重置查询参数
      refreshQueryParams: {
        isProduce: true, //是否生产计划单
        isShopDue: false, //是否门店预定单
        isShopMaterialOrder: false, //是否门店原料订货单
        isShopOrder: false, //是否门店订货单
        isSell: false, //是否销售订单
        isProductStorage: false, //是否产品入库
        isProduceOtherOrder: false, //是否其他订单,
        isProduceOtherMaterialOrder: false, //是否其他原料订单,
        pageNum: 1,
        pageSize: 15,
        isImport: 1,
      },
      queryParams: {
        isProduce: true, //是否生产计划单
        isShopDue: false, //是否门店预定单
        isShopMaterialOrder: false, //是否门店原料订货单
        isShopOrder: false, //是否门店订货单
        isSell: false, //是否销售订单
        isProductStorage: false, //是否产品入库
        isProduceOtherOrder: false, //是否其他订单,
        isProduceOtherMaterialOrder: false, //是否其他原料订单,
        pageNum: 1,
        pageSize: 15,
        isImport: 1,
      }, //查询
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      topOperatingH: 0, //顶部按钮高度
      paginationH: 40,
    };
  },
  watch: {
    //到货日期
    datetimeArrival(newVal) {
      if (newVal) {
        this.queryParams.beginArrivalDate = newVal[0];
        this.queryParams.endArrivalDate = newVal[1];
      } else {
        this.queryParams.beginArrivalDate = undefined;
        this.queryParams.endArrivalDate = undefined;
      }
    },
  },
  computed: {
    tableHeight() {
      return (
        this.vivwH - this.topOperatingH - this.searchH - this.paginationH - 62
      );
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById("wrap").clientHeight;
      this.topOperatingH =
        document.getElementById("topOperatingButton").clientHeight;
      this.searchH = document.getElementById("search-card").clientHeight;
    });
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      // 访问组件实例 vm 中的数据和方法
      // console.log('进来前')
      if (
        vm.$route.query.type === "distributeImport" &&
        vm.$route.query?.produceDeptId
      ) {
        // console.log('进来后', vm.$route.query?.produceDeptId)
        // vm.refreshQueryParams.produceDeptId = vm.$route.query.produceDeptId
        // vm.queryParams.produceDeptId = vm.refreshQueryParams.produceDeptId
        vm.$set(
          vm.refreshQueryParams,
          "produceDeptId",
          vm.$route.query.produceDeptId
        );
        vm.$set(
          vm.queryParams,
          "produceDeptId",
          vm.refreshQueryParams.produceDeptId
        );
        // console.log(
        //   '进来后queryParams',
        //   JSON.parse(JSON.stringify(vm.queryParams))
        // )
        await vm.getList();
      }
    });
  },
  methods: {
    handleResize() {
      this.vivwH = document.getElementById("wrap").clientHeight;
      this.topOperatingH =
        document.getElementById("topOperatingButton").clientHeight;
      this.searchH = document.getElementById("search-card").clientHeight;
    },
    //点击行选中
    handleRowClick(row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    /** 查询表格列表 */
    async getList() {
      this.loadingTable = true;
      console.log(
        "进来后queryParamsgetList",
        JSON.parse(JSON.stringify(this.queryParams))
      );
      const { rows, total } = await getSourceOrderData(this.queryParams);
      this.tableData = rows;
      this.total = total;
      this.loadingTable = false;
    },
    //确定
    async submitForm() {
      try {
        this.loading = true;
        if (this.selectionWizard?.length > 0) {
          const { data } = await getSourceOrderDetailData(this.selectionWizard);
          if (!data || !data?.detailItem || data?.detailItem?.length == 0) {
            this.$message.error("未查询到订单商品信息");
            this.loading = false;
            return;
          }
          // let goodsIds = []
          // let storeIds = []
          // data.detailItem.forEach(x => {
          //   goodsIds.push(x.goodsId)
          //   storeIds.push(x.storeId || x.outStoreId || x.inStoreId)
          // })
          // console.log(
          //   'storeIds',
          //   storeIds.filter(ite => typeof ite !== 'undefined')
          // )
          // //查库存
          // let stockQtys = []
          // if (storeIds?.length > 0 && goodsIds?.length > 0) {
          //   stockQtys = await findStockQtyAPI({
          //     goodsIds: goodsIds.filter(ite => typeof ite !== 'undefined'),
          //     storeIds: storeIds.filter(ite => typeof ite !== 'undefined')
          //   })
          //   console.log('stockQtys', stockQtys)
          // }

          data.detailItem.forEach(async (v) => {
            this.$set(v, "lineType", 1);
            //已入库数
            this.$set(v, "inUnitQty", v.unitAlreadyInQty);

            // if (
            //   goodsIds.includes(v.goodsId) &&
            //   (storeIds.includes(v.storeId) ||
            //     storeIds.includes(v.outStoreId) ||
            //     storeIds.includes(v.inStoreId))
            // ) {
            //   const obj = stockQtys?.data?.find(
            //     x =>
            //       x.goodsId == v.goodsId &&
            //       (x.storeId == v.storeId ||
            //         x.storeId == v.outStoreId ||
            //         x.storeId == v.inStoreId)
            //   )

            //   this.$set(v, 'storeQty', obj?.stockQty || 0)
            // }

            //分货数=订货数
            if (v.storeItem && v.storeItem.length > 0) {
              v.storeItem.forEach((item) => {
                this.$set(item, "distributeQty", item.orderQty);
              });
            }
          });

          this.$store.state.tagsView.visitedViews.splice(
            this.$store.state.tagsView.visitedViews.findIndex(
              (item) => item.path === this.$route.path
            ),
            1
          );
          this.$destroy();
          this.$router.push({
            name: "storeDistributeDetail",
            query: {
              sourceGoodsList: data.detailItem,
              importDetailItem: data.importDetailItem,
              produceDeptId: this.queryParams.produceDeptId,
              type: "distributeImport",
            },
          });
        } else {
          this.$store.state.tagsView.visitedViews.splice(
            this.$store.state.tagsView.visitedViews.findIndex(
              (item) => item.path === this.$route.path
            ),
            1
          );
          this.$destroy();
          this.$router.push({
            name: "storeDistributeDetail",
          });
        }

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    //取消
    getQuit() {
      // 关闭当前tab页签，打开新页签
      this.$tab.closeOpenPage({
        path: "/produce/bill/storeDistribute/storeDistributeDetail",
      });
    },
    //是否显示高级搜索
    getIsShowHigh(value) {
      this.showHigh = value;
      this.$nextTick(() => {
        this.handleResize();
      });
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.selectionWizard = selection.map((item) => {
        let obj = {
          importBillId: item.importBillId,
          importType: item.importType,
        };
        return obj;
      }); //单据id
    },
    //点击全选所有数据
    async selectAll(value) {
      if (value) {
        this.loadingTable = true;
        const res = await getSourceOrderData({
          ...this.queryParams,
          pageNum: 1,
          pageSize: this.total,
        });
        //重新渲染表格
        this.tableData = res.rows;
        //表格多选
        this.tableData.map((row) => {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row, value);
          });
        });
        this.loadingTable = false;
      } else {
        //取消全选
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection();
        });
        this.getList();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
//外面盒子
.wrap {
  padding: 40px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 搜索
  .navSearch {
    padding: 10px 10px 0px 20px;
    //筛选单据
    .checkOrder {
      margin-right: 50px;
    }
  }
  //源单信息表格
  .tableContent {
    margin: 6px 10px 10px 10px;
  }
}
::v-deep .el-form-item {
  margin-right: 10px;
  margin-bottom: 10px;
}
</style>
