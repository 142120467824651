<template>
  <div class="wrap" id="wrap">
    <div class="content" v-loading="loadingGoodsTable">
      <cardTitleCom cardTitle="原料明细" class="cardDetail">
        <template slot="cardContent">
          <div class="tableContent">
            <el-form ref="form" :rules="rules">
              <el-table tooltip-effect="dark" :data="tableDataGoods" border :max-height="tableHeight"
                :height="tableHeight" @row-click="handleRowClick">
                <el-table-column width="50" align="center">
                  <template v-slot="scope">
                    <el-radio v-model="radioItem" :label="scope.row">{{
                      ''
                    }}</el-radio>
                  </template>
                </el-table-column>
                <el-table-column label="序号" align="center" prop="userId" width="60" type="index" />
                <el-table-column label="商品编码" align="center" prop="goodsNo" />
                <el-table-column label="商品名称" align="center" prop="goodsName" />
                <el-table-column label="规格" align="center" prop="goodsSpec" />
                <el-table-column label="单位" align="center" prop="unitName" />
              </el-table>
            </el-form>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom :cardTitle="selectGoodName" class="cardAvailable">
        <template slot="rightCardTitle">
          <el-button class="marR10" type="primary" size="mini" @click="submitForm">保存
          </el-button>
        </template>
        <template slot="cardContent">
          <div class="tableContent">
            <el-table tooltip-effect="dark" :data="tableDataReplace" border :max-height="tableHeight"
              :height="tableHeight" v-loading="replaceLoading" @cell-mouse-enter="cellMouseEnter"
              @cell-mouse-leave="cellMouseLeave">
              <el-table-column align="center" :label="tableCellLabel" width="80">
                <template v-slot="scope">
                  <i v-if="scope.row.hoverRow" @click="
                      rowReplace('push', scope.$index, scope.row.usableGoodsId)
                    " class="el-icon-circle-plus operatePush"></i>
                  <i v-if="scope.row.hoverRow" @click="
                      rowReplace('del', scope.$index, scope.row.usableGoodsId)
                    " class="el-icon-remove operateDel"></i>
                  <div v-if="!scope.row.hoverRow">{{ scope.$index + 1 }}</div>
                </template>
              </el-table-column>

              <el-table-column label="替代商品编码" align="center" prop="usableGoodsNo" width="160">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.usableGoodsNo" :disabled="disabled">
                    <i slot="suffix" class="el-icon-more" @click="selectGoods"></i>
                  </el-input>
                </template>
              </el-table-column>

              <el-table-column label="替代商品名称" align="center" width="120" prop="usableGoodsName" />
              <el-table-column label="规格" align="center" prop="goodsSpec" />
              <el-table-column label="单位" align="center" prop="usableGoodsUnitId" width="120">
                <template slot-scope="scope">
                  <el-select v-model="scope.row.usableGoodsUnitId">
                    <el-option v-for="item in scope.row.units" :key="item.unitId" :label="item.unitName"
                      :value="item.unitId" />
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column prop="isMain" label="主要替代" width="80" align="center">
                <template slot-scope="scope">
                  <el-switch v-model="scope.row.isMain" :active-value="true" :inactive-value="false"
                    :disabled="scope.row.usableGoodsId == undefined"
                    @change="switchChange($event, scope.$index)"></el-switch>
                </template>
              </el-table-column>
              <el-table-column label="替代比例(%)" align="center" prop="exchangeScale" width="120">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.exchangeScale" />
                </template>
              </el-table-column>
              <el-table-column label="备注" align="center" prop="remark" width="160">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.remark"></el-input>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
      </cardTitleCom>
    </div>
    <!-- 弹窗 -->
    <Dialog ref="tablePage" :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import SelectGoods from '@/views/components/selectGoodsUp' //选择商品
import { listGoods } from '@/api/goods/goods' //商品列表
import { insteadList, insteadBatchSave } from '@/api/produce/bom/instead' //生产组、生产组商品详情信息
import Dialog from '@/components/Dialog'
export default {
  name: 'instead',
  components: { SelectGoods, cardTitleCom, Dialog },
  data() {
    return {
      //分页查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15,
      },
      //弹窗配置
      dialogOptions: {
        title: '选择商品',
        width: 1250,
        show: false,
        type: 'TreeAndTable',
        formData: this.$dialog({ key: 'goods' })
      },
      radioItem: undefined, //单选
      tableCellLabel: '序号',
      isSwitch: false, //开关变化
      selectGoodName: '可用物料', //当前选中的生产组的名称
      loadingGoodsTable: false, //生产组表格的加载
      replaceLoading: false, //商品表格的加载
      goodsId: undefined, //选中的商品id
      rules: {
        produceGroupNo: [
          { required: true, message: '名字不能为空', trigger: 'blur' }
        ],
        goodsName: [
          { required: true, message: '年龄不能为空', trigger: 'blur' }
        ]
      }, //校验表格
      tableDataGoods: [], //半成品、原料表格数据
      tableDataReplace: [], //可用物料表格数据
      disabled: false, //禁用状态
      vivwH: 0 //页面高度
    }
  },
  created() {
    this.getList()
  },
  computed: {
    tableHeight() {
      return this.vivwH - 65
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.vivwH = document.getElementById('wrap').clientHeight
    },
    //表格行hover时,显示操作加减号
    cellMouseEnter(row) {
      let eleIndex = this.tableDataReplace.indexOf(row)
      for (let index = 0; index < this.tableDataReplace.length; index++) {
        const element = this.tableDataReplace[index]
        if (eleIndex == index) {
          element['hoverRow'] = true
        } else {
          element['hoverRow'] = false
        }
      }
      this.tableCellLabel = '操作'
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave() {
      // 移除hover的事件
      for (let index = 0; index < this.tableDataReplace.length; index++) {
        const element = this.tableDataReplace[index]
        element['hoverRow'] = false
      }
      this.tableCellLabel = '序号'
    },
    //switch事件
    switchChange(switchValue, switchIndex) {
      if (this.isSwitch) return
      this.isSwitch = true
      for (let index = 0; index < this.tableDataReplace.length; index++) {
        //打开主要替代
        if (switchValue) {
          if (index != switchIndex) {
            this.tableDataReplace[index].isMain = false
          }
        } else {
          this.tableDataReplace[0].isMain = true
        }
      }
      this.isSwitch = false
    },
    //确定
    async submitForm() {
      this.tableDataReplace.forEach(v => {
        this.$set(v, 'goodsId', this.goodsId)
      })
      await insteadBatchSave(this.tableDataReplace)
      this.getList()
      this.$message({
        type: 'success',
        message: '可用物料替代成功!'
      })
    },

    //点击生产组当前行
    async handleRowClick(row) {
      // 切换行选中状态
      this.radioItem = row
      this.replaceLoading = true
      //替换标题
      this.selectGoodName = row.goodsName
      //选中的商品id左
      this.goodsId = row.goodsId
      //替换表格
      const res = await insteadList({
        goodsId: row.goodsId,
        pageNum: 1,
        pageSize: 99999,
      })
      this.tableDataReplace = res.rows
      this.replaceLoading = false
    },
    //生产组信息列表
    async getList() {
      this.loadingGoodsTable = true
      const res = await listGoods({
        goodsTypes: [2, 3], pageNum: 1,
        pageSize: 9999999,
      })
      this.tableDataGoods = res.rows
      this.loadingGoodsTable = false
    },
    //选择商品方法
    selectGoods() {
      this.dialogOptions.formData = this.$dialog({ key: 'goods' })
      this.dialogOptions.formData.table.pagination.goodsTypes = [2, 3]
      this.dialogOptions.show = true
    },
    //弹窗确定事件
    async handleEvent(type, row) {
      switch (type) {
        case 'dialogChange':
          this.getGoodS(this.dialogOptions.formData.table.check)
          break
        default:
          break
      }
    },
    //选中的商品
    getGoodS(value) {
      if (value && value.length == 0) return
      //过滤重复
      value = value.filter(item => {
        return this.tableDataReplace.every(v => v.usableGoodsId != item.goodsId)
      })
      //单据赋值
      value.forEach(item => {
        // 使用vue的$set方法更新数组数据,使视图可编辑
        item.usableGoodsNo = item.goodsNo
        this.$set(item, 'usableGoodsNo', item.goodsNo)
        this.$set(item, 'usableGoodsName', item.goodsName)
        this.$set(item, 'goodsSpec', item.goodsSpec)
        this.$set(item, 'usableGoodsUnitId', item.unitId)
        this.$set(item, 'isMain', false)
        this.$set(item, 'exchangeScale', 100)
        this.$set(item, 'remark', '')
        this.$set(item, 'units', item.units)
        this.$set(item, 'usableGoodsId', item.goodsId)
      })
      //清空没有数据的对象
      this.tableDataReplace = this.tableDataReplace.filter(
        item =>
          (!(JSON.stringify(item) === '{}') &&
            !(item.usableGoodsId === undefined)) ||
          !(item.usableGoodsId === undefined)
      )
      this.tableDataReplace.push(...value)
    },
    //可用物料表格当中增加/减少一行方法
    rowReplace(name, index, usableGoodsId) {
      if (name === 'push') {
        this.tableDataReplace.splice(index + 1, 0, {})
      } else {
        if (usableGoodsId == this.goodsId)
          return this.$message.error('默认数据不能删除 !')
        this.tableDataReplace.splice(index, 1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// 最大盒子
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  //关系情况
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    //左边
    .cardDetail {
      width: 35%;
      margin-right: 10px;
    }
    //商品表格卡片
    .cardAvailable {
      width: 65%;
    }
    .tableContent {
      margin: 6px 10px 10px 10px;
    }
  }
}
//卡片默认边距0
::v-deep .el-card__body {
  padding: 0;
  margin: 0;
}
</style>
