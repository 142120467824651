var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wrap",
    },
    [
      _c(
        "div",
        { staticClass: "navBtnTop x-c-end" },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeStep !== 0,
                  expression: "activeStep !== 0",
                },
              ],
              attrs: { size: "mini" },
              on: { click: _vm.lastStep },
            },
            [_vm._v("上一步")]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeStep !== 3,
                  expression: "activeStep !== 3",
                },
              ],
              attrs: { size: "mini" },
              on: { click: _vm.nextStep },
            },
            [_vm._v("下一步")]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeStep === 3,
                  expression: "activeStep === 3",
                },
              ],
              staticClass: "marR10",
              attrs: { type: "primary", size: "mini", disabled: _vm.disabled },
              on: {
                click: function ($event) {
                  return _vm.submitForm(true)
                },
              },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-dropdown",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeStep === 3,
                  expression: "activeStep === 3",
                },
              ],
              attrs: {
                "split-button": "",
                icon: "el-icon-circle-check",
                disabled: _vm.showAudit,
                size: "mini",
              },
              on: {
                click: function ($event) {
                  return _vm.auditBill("审核")
                },
                command: _vm.auditBill,
              },
            },
            [
              _vm._v(" 审核 "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c("el-dropdown-item", { attrs: { command: "反审核" } }, [
                    _vm._v("反审核"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showAdd,
                  expression: "showAdd",
                },
              ],
              attrs: { type: "primary", icon: "el-icon-plus", size: "mini" },
              on: { click: _vm.handleAdd },
            },
            [_vm._v("新增")]
          ),
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.getQuit } },
            [_vm._v("退出")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.batcheditForm, rules: _vm.rules },
            },
            [
              _c(
                "cardTitleCom",
                {
                  attrs: {
                    cardTitle: "当前进度",
                    billStatus: _vm.batcheditForm.billStatus,
                  },
                },
                [
                  _c("template", { slot: "cardContent" }, [
                    _c(
                      "div",
                      { staticClass: "theSteps" },
                      [
                        _c(
                          "el-steps",
                          {
                            attrs: {
                              active: _vm.activeStep,
                              "finish-status": "success",
                            },
                          },
                          [
                            _c("el-step", {
                              staticClass: "firstStep",
                              attrs: { title: "批量修改设置" },
                            }),
                            _c("el-step", {
                              staticClass: "secondStep",
                              attrs: { title: "选择配方" },
                            }),
                            _c("el-step", {
                              staticClass: "thirdStep",
                              attrs: { title: "信息确认与执行" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
              _c(
                "cardTitleCom",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeStep === 0,
                      expression: "activeStep === 0",
                    },
                  ],
                  attrs: { cardTitle: "选择修改类型" },
                },
                [
                  _c("template", { slot: "cardContent" }, [
                    _c(
                      "div",
                      { staticClass: "modifyType" },
                      [
                        _c(
                          "el-radio-group",
                          {
                            attrs: {
                              disabled: _vm.batcheditForm.billId != undefined,
                            },
                            model: {
                              value: _vm.batcheditForm.optType,
                              callback: function ($$v) {
                                _vm.$set(_vm.batcheditForm, "optType", $$v)
                              },
                              expression: "batcheditForm.optType",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "0" } }, [
                              _vm._v("配方原料替换"),
                            ]),
                            _c("el-radio", { attrs: { label: "1" } }, [
                              _vm._v("配方新增子件"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
              _c(
                "cardTitleCom",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isBomReplaceCard,
                      expression: "isBomReplaceCard",
                    },
                  ],
                  attrs: { cardTitle: "待替换子件" },
                },
                [
                  _c("template", { slot: "cardContent" }, [
                    _c(
                      "div",
                      { staticClass: "tableContent" },
                      [
                        _c(
                          "el-table",
                          {
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.batcheditForm.insteadGoodsItems,
                              border: "",
                              "max-height": _vm.tableHeightReplace,
                              height: _vm.tableHeightReplace,
                            },
                          },
                          [
                            _c(
                              "el-table-column",
                              { attrs: { label: "商品编码", align: "center" } },
                              [
                                _c("template", { slot: "header" }, [
                                  _c(
                                    "i",
                                    { staticStyle: { color: "#ff4949" } },
                                    [_vm._v("* ")]
                                  ),
                                  _c("span", [_vm._v("商品编码")]),
                                ]),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "替换前",
                                    width: "180",
                                    align: "center",
                                    prop: "goodsNo",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop:
                                                  "insteadGoodsItems." +
                                                  scope.$index +
                                                  ".goodsNo",
                                                rules:
                                                  _vm.rules[
                                                    "insteadGoodsItems.goodsNo"
                                                  ],
                                              },
                                            },
                                            [
                                              _c(
                                                "el-input",
                                                {
                                                  attrs: {
                                                    size: "mini",
                                                    disabled:
                                                      _vm.disabled ||
                                                      _vm.activeStep == 3,
                                                  },
                                                  on: {
                                                    blur: _vm.onBlurGoodsNo,
                                                  },
                                                  model: {
                                                    value: scope.row.goodsNo,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "goodsNo",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.goodsNo",
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-more more",
                                                    attrs: { slot: "suffix" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.selectGoods(
                                                          "goodsNo"
                                                        )
                                                      },
                                                    },
                                                    slot: "suffix",
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "替换后",
                                    width: "180",
                                    align: "center",
                                    prop: "changeGoodsNo",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop:
                                                  "insteadGoodsItems." +
                                                  scope.$index +
                                                  ".changeGoodsNo",
                                                rules:
                                                  _vm.rules[
                                                    "insteadGoodsItems.changeGoodsNo"
                                                  ],
                                              },
                                            },
                                            [
                                              _c(
                                                "el-input",
                                                {
                                                  attrs: {
                                                    size: "mini",
                                                    disabled:
                                                      _vm.disabled ||
                                                      _vm.activeStep == 3,
                                                  },
                                                  on: {
                                                    blur: _vm.onBlurGoodsNoCh,
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row.changeGoodsNo,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "changeGoodsNo",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.changeGoodsNo",
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-more more",
                                                    attrs: { slot: "suffix" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.selectGoods(
                                                          "changeGoodsNo"
                                                        )
                                                      },
                                                    },
                                                    slot: "suffix",
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              2
                            ),
                            _c(
                              "el-table-column",
                              { attrs: { label: "商品名称", align: "center" } },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    "show-overflow-tooltip": "",
                                    label: "替换前",
                                    align: "center",
                                    prop: "goodsName",
                                    width: "140",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    "show-overflow-tooltip": "",
                                    label: "替换后",
                                    align: "center",
                                    prop: "changeGoodsName",
                                    width: "140",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-table-column",
                              { attrs: { label: "规格", align: "center" } },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    "show-overflow-tooltip": "",
                                    label: "替换前",
                                    align: "center",
                                    prop: "goodsSpec",
                                    width: "120",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    "show-overflow-tooltip": "",
                                    label: "替换后",
                                    align: "center",
                                    prop: "changeGoodsSpec",
                                    width: "120",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-table-column",
                              { attrs: { label: "单位", align: "center" } },
                              [
                                _c("template", { slot: "header" }, [
                                  _c(
                                    "i",
                                    { staticStyle: { color: "#ff4949" } },
                                    [_vm._v("* ")]
                                  ),
                                  _c("span", [_vm._v("单位")]),
                                ]),
                                _c("el-table-column", {
                                  attrs: {
                                    "show-overflow-tooltip": "",
                                    label: "替换前",
                                    align: "center",
                                    prop: "unitName",
                                    width: "120",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop:
                                                  "insteadGoodsItems." +
                                                  scope.$index +
                                                  ".unitId",
                                                rules:
                                                  _vm.rules[
                                                    "insteadGoodsItems.unitId"
                                                  ],
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    size: "mini",
                                                    disabled:
                                                      _vm.disabled ||
                                                      _vm.activeStep == 3,
                                                  },
                                                  model: {
                                                    value: scope.row.unitId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "unitId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.unitId",
                                                  },
                                                },
                                                _vm._l(
                                                  scope.row.units,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.unitId,
                                                      attrs: {
                                                        label: item.unitName,
                                                        value: item.unitId,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    "show-overflow-tooltip": "",
                                    label: "替换后",
                                    align: "center",
                                    prop: "exchangeUnitId",
                                    width: "120",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop:
                                                  "insteadGoodsItems." +
                                                  scope.$index +
                                                  ".exchangeUnitId",
                                                rules:
                                                  _vm.rules[
                                                    "insteadGoodsItems.exchangeUnitId"
                                                  ],
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    size: "mini",
                                                    disabled:
                                                      _vm.disabled ||
                                                      _vm.activeStep == 3,
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row.exchangeUnitId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "exchangeUnitId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.exchangeUnitId",
                                                  },
                                                },
                                                _vm._l(
                                                  scope.row.changeUnits,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.unitId,
                                                      attrs: {
                                                        label: item.unitName,
                                                        value: item.unitId,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              2
                            ),
                            _c("el-table-column", {
                              attrs: {
                                label: "替换比例(%)",
                                align: "center",
                                "min-width": "120",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-input", {
                                        attrs: {
                                          size: "mini",
                                          disabled:
                                            _vm.disabled || _vm.activeStep == 3,
                                          maxlength: "7",
                                        },
                                        model: {
                                          value: scope.row.exchangeScale,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "exchangeScale",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "scope.row.exchangeScale",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "损耗比例(%)",
                                align: "center",
                                "min-width": "120",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-input", {
                                        attrs: {
                                          size: "mini",
                                          disabled:
                                            _vm.disabled || _vm.activeStep == 3,
                                          maxlength: "7",
                                        },
                                        model: {
                                          value: scope.row.loseRate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "loseRate",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "scope.row.loseRate",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "备注",
                                align: "center",
                                prop: "remark",
                                "min-width": "120",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-input", {
                                        attrs: {
                                          size: "mini",
                                          disabled:
                                            _vm.disabled || _vm.activeStep == 3,
                                          maxlength: "80",
                                        },
                                        model: {
                                          value: scope.row.remark,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "remark",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "scope.row.remark",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
              _c(
                "cardTitleCom",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isBomAddCard,
                      expression: "isBomAddCard",
                    },
                  ],
                  attrs: { cardTitle: "配方新增子件" },
                },
                [
                  _c("template", { slot: "cardContent" }, [
                    _c(
                      "div",
                      { staticClass: "tableContent" },
                      [
                        _c(
                          "el-table",
                          {
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.batcheditForm.insteadGoodsItems,
                              border: "",
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                            },
                            on: {
                              "cell-mouse-enter": function ($event) {
                                return _vm.cellMouseEnter(
                                  $event,
                                  "insteadGoodsItems"
                                )
                              },
                              "cell-mouse-leave": function ($event) {
                                return _vm.cellMouseLeave("insteadGoodsItems")
                              },
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                label: _vm.tableCellLabel,
                                width: "80",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.hoverRow
                                        ? _c("i", {
                                            staticClass:
                                              "el-icon-circle-plus operatePush",
                                            on: {
                                              click: function ($event) {
                                                return _vm.rowAddBom(
                                                  "push",
                                                  scope.$index
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                      scope.row.hoverRow
                                        ? _c("i", {
                                            staticClass:
                                              "el-icon-remove operateDel",
                                            on: {
                                              click: function ($event) {
                                                return _vm.rowAddBom(
                                                  "del",
                                                  scope.$index
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                      !scope.row.hoverRow
                                        ? _c("div", [
                                            _vm._v(_vm._s(scope.$index + 1)),
                                          ])
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品编码",
                                width: "180",
                                align: "center",
                                prop: "goodsNo",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-input",
                                        {
                                          attrs: {
                                            disabled:
                                              _vm.disabled ||
                                              _vm.activeStep == 3,
                                          },
                                          model: {
                                            value: scope.row.goodsNo,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "goodsNo",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.goodsNo",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-more more",
                                            attrs: { slot: "suffix" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.selectGoods(
                                                  "goodsNoAdd"
                                                )
                                              },
                                            },
                                            slot: "suffix",
                                          }),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品名称",
                                align: "center",
                                prop: "goodsName",
                                width: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "规格",
                                align: "center",
                                prop: "goodsSpec",
                                width: "130",
                              },
                            }),
                            _c(
                              "el-table-column",
                              {
                                attrs: {
                                  label: "单位",
                                  align: "center",
                                  prop: "unitId",
                                  width: "110",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "insteadGoodsItems." +
                                                scope.$index +
                                                ".unitId",
                                              rules:
                                                _vm.rules[
                                                  "insteadGoodsItems.unitId"
                                                ],
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                attrs: {
                                                  disabled:
                                                    _vm.disabled ||
                                                    _vm.activeStep == 3,
                                                  filterable: "",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.unitPriceChange(
                                                      $event,
                                                      scope.row
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: scope.row.unitId,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "unitId",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.unitId",
                                                },
                                              },
                                              _vm._l(
                                                scope.row.units,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.unitId,
                                                    attrs: {
                                                      label: item.unitName,
                                                      value: item.unitId,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _c("template", { slot: "header" }, [
                                  _c(
                                    "i",
                                    { staticStyle: { color: "#ff4949" } },
                                    [_vm._v("* ")]
                                  ),
                                  _c("span", [_vm._v("单位")]),
                                ]),
                              ],
                              2
                            ),
                            _c(
                              "el-table-column",
                              {
                                attrs: {
                                  label: "单位用量",
                                  align: "center",
                                  width: "130",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "insteadGoodsItems." +
                                                scope.$index +
                                                ".oneUseQty",
                                              rules:
                                                _vm.rules[
                                                  "insteadGoodsItems.oneUseQty"
                                                ],
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                disabled:
                                                  _vm.disabled ||
                                                  _vm.activeStep == 3,
                                              },
                                              nativeOn: {
                                                keyup: function ($event) {
                                                  return _vm.onkeyupOneUseQty(
                                                    scope.row
                                                  )
                                                },
                                              },
                                              model: {
                                                value: scope.row.oneUseQty,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "oneUseQty",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.oneUseQty",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _c("template", { slot: "header" }, [
                                  _c(
                                    "i",
                                    { staticStyle: { color: "#ff4949" } },
                                    [_vm._v("* ")]
                                  ),
                                  _c("span", [_vm._v("单位用量")]),
                                ]),
                              ],
                              2
                            ),
                            _c("el-table-column", {
                              attrs: {
                                label: "单位成本",
                                align: "center",
                                prop: "oneCostMoney",
                                width: "130",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "损耗率(%)",
                                align: "center",
                                width: "120",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-input", {
                                        attrs: {
                                          disabled:
                                            _vm.disabled || _vm.activeStep == 3,
                                        },
                                        model: {
                                          value: scope.row.loseRate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "loseRate",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "scope.row.loseRate",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "备注",
                                align: "center",
                                prop: "remark",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-input", {
                                        attrs: {
                                          disabled:
                                            _vm.disabled || _vm.activeStep == 3,
                                        },
                                        model: {
                                          value: scope.row.remark,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "remark",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "scope.row.remark",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
              _c(
                "cardTitleCom",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isBomModifyCard,
                      expression: "isBomModifyCard",
                    },
                  ],
                  attrs: { cardTitle: "选择需要修改的BOM单" },
                },
                [
                  _c("template", { slot: "cardContent" }, [
                    _c(
                      "div",
                      { staticClass: "tableContent" },
                      [
                        _c(
                          "el-table",
                          {
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.batcheditForm.insteadBillItems,
                              border: "",
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                            },
                            on: {
                              "cell-mouse-enter": function ($event) {
                                return _vm.cellMouseEnter(
                                  $event,
                                  "insteadBillItems"
                                )
                              },
                              "cell-mouse-leave": function ($event) {
                                return _vm.cellMouseLeave("insteadBillItems")
                              },
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                label: _vm.tableCellLabel,
                                width: "80",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.hoverRow
                                        ? _c("i", {
                                            staticClass:
                                              "el-icon-circle-plus operatePush",
                                            on: {
                                              click: function ($event) {
                                                return _vm.rowTwo(
                                                  "push",
                                                  scope.$index
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                      scope.row.hoverRow
                                        ? _c("i", {
                                            staticClass:
                                              "el-icon-remove operateDel",
                                            on: {
                                              click: function ($event) {
                                                return _vm.rowTwo(
                                                  "del",
                                                  scope.$index
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                      !scope.row.hoverRow
                                        ? _c("div", [
                                            _vm._v(_vm._s(scope.$index + 1)),
                                          ])
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "配方编号",
                                width: "253",
                                align: "center",
                                prop: "bomNo",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop:
                                              "insteadBillItems." +
                                              scope.$index +
                                              ".bomNo",
                                            rules:
                                              _vm.rules[
                                                "insteadBillItems.bomNo"
                                              ],
                                          },
                                        },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              attrs: {
                                                disabled:
                                                  _vm.disabled ||
                                                  _vm.activeStep == 3,
                                              },
                                              nativeOn: {
                                                keyup: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  return _vm.keyupModifyBill(
                                                    "bomNo",
                                                    $event,
                                                    scope.$index
                                                  )
                                                },
                                              },
                                              model: {
                                                value: scope.row.bomNo,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "bomNo",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.bomNo",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-more more",
                                                attrs: { slot: "suffix" },
                                                on: {
                                                  click: _vm.ModifyBillDia,
                                                },
                                                slot: "suffix",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品编码",
                                width: "253",
                                align: "center",
                                prop: "goodsNo",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品名称",
                                align: "center",
                                width: "196",
                                prop: "goodsName",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品类别",
                                align: "center",
                                prop: "categoryName",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                width: "196",
                                label: "品牌名称",
                                align: "center",
                                prop: "brandName",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "isStop",
                                label: "启用",
                                width: "120",
                                "show-overflow-tooltip": true,
                                align: "center",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-switch", {
                                        attrs: {
                                          disabled: true,
                                          "active-value": false,
                                          "inactive-value": true,
                                        },
                                        model: {
                                          value: scope.row.isStop,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "isStop", $$v)
                                          },
                                          expression: "scope.row.isStop",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单据状态",
                                align: "center",
                                prop: "bomStatusName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("Dialog", {
        ref: "tablePage",
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
      _c("selectBomService", {
        ref: "bomService",
        attrs: {
          OpenBomService: _vm.OpenBomService,
          queryCode: _vm.queryCodeBomService,
        },
        on: {
          "update:OpenBomService": function ($event) {
            _vm.OpenBomService = $event
          },
          "update:open-bom-service": function ($event) {
            _vm.OpenBomService = $event
          },
          bomServiceListCom: _vm.bomServiceListCom,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }