<template>
  <div class="wrap">
    <TablePage v-model="options" @handleEvent="handleEvent"></TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { getRoomOrder } from "@/api/produce/decoration/index.js";
export default {
  name: "orderQuery",
  components: { TablePage },
  data() {
    return {
      payTypes: [], // 支付方式
      options: {
        summary: "",
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        getListApi: getRoomOrder,
        body: {},
        title: "裱花订单查询",
        rowKey: "id",
        search: [
          {
            label: "日期",
            type: "daterange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "daterange",
            type: "timeRange",
            data: ["day", "yesterday", "week"],
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
        ],
        columns: [
          { prop: "decorationRoomName", label: "裱花间名称", minWidth: "160" },
          { prop: "decorationRoomNo", label: "裱花间编号", minWidth: "160" },
          { prop: "billDate", label: "订单时间", minWidth: "160" },
          { prop: "orderStatus", label: "订单状态", minWidth: "100" },
          { prop: "orderTypeName", label: "订单类型", minWidth: "100" },
          { prop: "orderPrintCount", label: "打印次数", minWidth: "100" },
          { prop: "unitMoney", label: "金额", minWidth: "100" },
          { prop: "unitQty", label: "数量", minWidth: "100" },
          { prop: "fetchTypeName", label: "取货方式", minWidth: "120" },
          { prop: "shopName", label: "取货门店", minWidth: "160" },
          { prop: "deliveryDate", label: "取货时间", minWidth: "160" },
          { prop: "receiverName", label: "姓名", minWidth: "120" },
          { prop: "receiverPhone", label: "电话", minWidth: "120" },
          { prop: "receiverAddress", label: "地址", minWidth: "160" },
          { prop: "remark", label: "备注", minWidth: "160" },
        ],
        list: [],
      },
      shopInfo: [],
      tenantInfo: [],
    };
  },
  async mounted() {},
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "getListed":
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
