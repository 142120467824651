<template>
  <el-form ref="form" :model="form" :rules="rules" label-width="96px">
    <div class="wrap" v-loading="loading" id="wrap">
      <topOperatingButton :disabled="disabled" :showAudit="showAudit" :isAddBtn="showAdd" @submitForm="submitForm"
        @addBill="addBill" @auditBill="auditBill" @handleAdd="handleAdd" @getQuit="getQuit" id="topOperatingButton">
        <template slot="specialDiyBtn">
          <el-button size="mini" :disabled="single" @click="auditBill('终止')">终止
          </el-button>
        </template>
      </topOperatingButton>
      <cardTitleCom cardTitle="基本信息" :billStatus="form.billStatus" id="basicInformation">
        <template slot="cardContent">
          <div class="x-f marT10 marB15">
            <el-form-item label="单据编号" prop="billNo">
              <el-input class="inputWidth" size="mini" :disabled="true" v-model="form.billNo"
                placeholder="单据编号"></el-input>
            </el-form-item>
            <el-form-item label="单据日期" prop="billDate">
              <el-date-picker style="width: 250px" size="mini" v-model="form.billDate" type="date" placeholder="单据日期"
                :disabled="disabled" value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="到货日期" prop="arrivalDate">
              <el-date-picker style="width: 250px" size="mini" v-model="form.arrivalDate" type="date" placeholder="到货日期"
                :disabled="disabled" value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="订货仓库" prop="orderStoreId">
              <SelectRemote v-model="form.orderStoreId" style="width: 250px" :option="
                  $select({
                    key: 'listStore',
                    option: {
                      option: {
                        disabled: disabled,
                        remoteBody: { storeTypes: [0] },
                          showItem: [
                          {
                            storeId: form.orderStoreId,
                            storeName: form.orderStoreName,
                          },
                        ],
                      }
                    }
                  }).option
                " />
            </el-form-item>
          </div>
          <div class="x-f marB20">
            <el-form-item label="生产工厂" prop="produceDeptId">
              <SelectRemote @selectChange="changeProduceGroupChange" v-model="form.produceDeptId" style="width: 250px"
                :option="
                  $select({
                    key: 'listProduceDept',
                    option: { option: { disabled: disabled } }
                  }).option
                " />
            </el-form-item>
            <el-form-item label="生产班次" prop="produceOrderId">
              <SelectRemote v-model="form.produceOrderId" style="width: 250px"
                :disabled="disabled || !form.produceDeptId" :option="listProduceOrder" />
            </el-form-item>
            <el-form-item label="单据备注" prop="billRemark">
              <el-input class="inputWidth" :disabled="disabled" v-model="form.billRemark" type="textarea"
                placeholder="备注长度介于 1 和 80 字符之间" maxlength="80" :autosize="{ minRows: 1, maxRows: 1 }" />
            </el-form-item>
          </div>
        </template>
      </cardTitleCom>
      <!-- 表格商品信息卡片 -->
      <cardTitleCom cardTitle="商品信息" class="headGoodsTable">
        <template slot="cardContent">
          <!-- 商品信息按钮和表格整体盒子 -->
          <div class="goodsMessage">
            <el-table :data="form.billDetailItems" border show-summary :summary-method="getSummaries"
              @cell-mouse-enter="cellMouseEnter" @cell-mouse-leave="cellMouseLeave" :max-height="tableHeight"
              :height="tableHeight">
              <el-table-column align="center" :label="tableCellLabel" width="80">
                <template v-slot="scope">
                  <i v-if="scope.row.hoverRow" :disabled="disabled" @click="row('push', scope.$index)"
                    class="el-icon-circle-plus operatePush"></i>
                  <i v-if="scope.row.hoverRow" :disabled="disabled" @click="row('del', scope.$index)"
                    class="el-icon-remove operateDel"></i>
                  <div v-if="!scope.row.hoverRow">{{ scope.$index + 1 }}</div>
                </template>
              </el-table-column>
              <el-table-column label="商品编码" width="160" align="center" prop="goodsNo">
                <template slot-scope="scope">
                  <SelectRemote size="mini" :disabled="disabled" v-model="scope.row.goodsNo" :option="optionGoods"
                    :filterable="true" placeholder="选择商品" />
                </template>
              </el-table-column>
              <el-table-column label="商品名称" align="center" prop="goodsName" width="180">
              </el-table-column>
              <el-table-column label="条码" width="180" align="center" prop="barcode">
              </el-table-column>
              <el-table-column label="规格" align="center" prop="goodsSpec" width="150">
                <template slot-scope="scope">
                  <el-form-item>
                    <el-select @change="unitSalePriceChange($event, scope.row)" v-model="scope.row.unitId"
                      :disabled="disabled">
                      <el-option v-for="item in scope.row.units" :key="item.unitId" :label="item.goodsSpec"
                        :value="item.unitId" />
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="单位" align="center" prop="unitName" width="120">
                <template slot="header">
                  <i style="color: #ff4949">* </i>
                  <span>单位</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item :prop="'billDetailItems.' + scope.$index + '.unitId'"
                    :rules="rules[`billDetailItems.unitId`]">
                    <el-select @change="unitSalePriceChange($event, scope.row)" v-model="scope.row.unitId"
                      :disabled="disabled">
                      <el-option v-for="item in scope.row.units" :key="item.unitId" :label="item.unitName"
                        :value="item.unitId" />
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="数量" align="center" width="120">
                <template slot="header">
                  <i style="color: #ff4949">* </i>
                  <span>数量</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item :prop="'billDetailItems.' + scope.$index + '.unitQty'"
                    :rules="rules[`billDetailItems.unitQty`]">
                    <el-input type="number" @keyup.native="onkeyupUnitQty(scope.row)" v-model="scope.row.unitQty"
                      :disabled="disabled" maxlength="7" @input="
                        limitInputlength(
                          scope.row.unitQty,
                          scope.row,
                          'unitQty',
                          true
                        )
                      "></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="单价(元)" align="center" width="120" prop="unitPrice">
              </el-table-column>
              <el-table-column label="金额(元)" align="center" width="120" prop="unitMoney">
                <template slot-scope="scope">
                  <span>{{ scope.row.unitMoney }}</span>
                </template>
              </el-table-column>
              <el-table-column label="备注" align="center" prop="remark">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.remark" :disabled="disabled" maxlength="80"></el-input>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
      </cardTitleCom>
      <SelectGoods :OpenGoods.sync="OpenGoods" :queryCode="queryCode" @getGoodS="getGoodS" ref="selectGoods">
      </SelectGoods>
    </div>
  </el-form>
</template>

<script>
import SelectGoods from '@/views/components/selectGoodsUp'
import { listGoods } from '@/api/goods/goods' //商品列表
import {
  otherOrderSave,
  otherOrderGetDetail,
  otherOrderUpdate,
  otherOrderUpdateBillStatus
} from '@/api/produce/bill/storeDistribute' //其他订货单

import { getNewDate } from '@/utils/newDate' //到货时间
import { getBillNo } from '@/api/codeRule' //单据号
import { unitSalePriceChange } from '@/utils/unitChange' //多级单位切换函数
import topOperatingButton from '@/views/components/topOperatingButton' //顶部按钮
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import SelectRemote from '@/components/tablePage/select/select-remote/index.vue'
import { limitInputlength } from '@/utils/index.js' //保留几位小数
import { produceDeptList } from '@/api/produce/sets/produceDept' //生产工厂
export default {
  name: 'storeOrderDetail',
  components: {
    SelectGoods,
    topOperatingButton,
    cardTitleCom,
    SelectRemote
  },
  data() {
    return {
      isSubmit: false,
      optionGoods: this.$select({
        key: 'listGoods',
        option: {
          option: {
            noEcho: true,
            remoteBody: { goodsTypes: [1, 2] },
            change: (propValue, row) => {
              if (propValue) {
                const findIndex = this.form.billDetailItems.findIndex(
                  item => item.goodsNo === propValue
                )
                let rows = JSON.parse(JSON.stringify(row))
                rows.findIndex = findIndex
                this.getGoodS([rows])
              } else {
                this.$nextTick(() => {
                  console.log('11')
                })
              }
            },
            tableChange: (propValue, row) => {
              this.getGoodS(row.check)
            },
            buttons: [
              {
                type: 'more',
                option: {
                  title: '选择商品',
                  width: 1250,
                  type: 'TreeAndTable',
                  formData: this.$dialog({
                    key: 'goods',
                    option: {
                      // treeApi: undefined,
                      queryParams: { goodsTypes: [1, 2] },
                      table: {
                        ...this.$dialog({ key: 'goods' }).table
                      }
                    }
                  })
                }
              }
            ]
          }
        }
      }).option,
      listProduceOrder: this.$select({
        key: 'listProduceOrder',
        option: {
          option: {
            showItem: []
          }
        }
      }).option,
      tableCellLabel: '序号',
      showAudit: true, //审核
      //打印
      PrintObj: {
        id: 'print',
        popTitle: '打印的标题',
        extraCss: 'https://www.google.com,https://www.google.com',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      //选择商品信息开关
      OpenGoods: false,
      //传送商品参数
      queryCode: undefined,
      //当前表格宽度
      scrollLeft: null,
      //遮罩层
      loading: false,
      //标识已审核
      disabled: false,
      //终止
      single: true,
      //新增按钮
      showAdd: false,
      oldForm: {}, //旧的表单
      //表单数据
      form: {
        billStatus: '', //单据审核状态
        billNo: null, //单据编号
        billDate: null, //单据日期
        arrivalDate: null, //到货日期
        produceOrderId: null, //生产班次
        orderStoreId: null, //订货仓库
        produceDeptId: null, //生产工厂
        billRemark: null, //备注
        //单据表格-订单明细
        billDetailItems: [{}]
      },
      //表单校验
      rules: {
        billNo: [
          {
            required: true,
            message: '请输入单据编号',
            trigger: ['blur', 'change']
          }
        ],
        billDate: [
          {
            required: true,
            message: '请选择单据日期',
            trigger: ['blur', 'change']
          }
        ],
        orderStoreId: [
          {
            required: true,
            message: '请选择订货仓库',
            trigger: ['blur', 'change']
          }
        ],
        produceDeptId: [
          {
            required: true,
            message: '请选择生产工厂',
            trigger: ['blur', 'change']
          }
        ],
        arrivalDate: [
          {
            required: true,
            message: '请选择到货日期',
            trigger: ['blur', 'change']
          }
        ],
        'billDetailItems.unitId': [
          {
            required: true,
            message: '单位不能为空',
            trigger: ['blur', 'change']
          }
        ],
        'billDetailItems.unitQty': [
          {
            required: true,
            message: '数量不能为空',
            trigger: ['blur']
          },
          {
            pattern: /^([1-9]\d*|0\.\d*[1-9]\d*|[1-9]\d*\.\d*[1-9]\d*)$/,
            message: '数值类型且不为0',
            trigger: ['blur', 'change']
          }
        ]
      },
      vivwH: 0, //页面高度
      topOperatingH: 0, //顶部按钮高度
      basicH: 0 //基本信息
    }
  },
  watch: {
    //侦听是否开启关闭按钮
    'form.billStatus': {
      handler(newVal) {
        if (this.form.billStatus === '2') {
          //新增按钮
          this.showAdd = true
          //打开关闭按钮
          this.single = false
          //禁用输入按钮、保存按钮
          this.disabled = true
          //打开审核按钮
          this.showAudit = false
        } else if (this.form.billStatus === '3') {
          //新增按钮
          this.showAdd = false
          //禁用关闭按钮
          this.single = true
          //禁用输入按钮、保存按钮
          this.disabled = true
          //禁用审核按钮
          this.showAudit = true
        } else if (this.form.billStatus === '0') {
          //新增按钮
          this.showAdd = false
          //打开输入按钮、保存按钮
          this.disabled = false
          //禁用关闭按钮
          this.single = true
          //禁用审核按钮
          this.showAudit = false
        } else if (this.form.billStatus === '4') {
          //新增按钮
          this.showAdd = false
          //禁用关闭按钮
          this.single = true
          //禁用输入按钮、保存按钮
          this.disabled = true
          //禁用审核按钮
          this.showAudit = true
        } else if (this.form.billStatus === '') {
          //新增按钮
          this.showAdd = false
          //禁用审核按钮
          this.showAudit = true
          //禁用关闭按钮
          this.single = true
          //打开输入按钮、保存按钮
          this.disabled = false
        }
      },
      immediate: true
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      vm.loading = true
      vm.reset()
      // 访问组件实例 vm 中的数据和方法
      if (vm.$route.query.type === 'Update') {
        if (vm.$route.query.billId) {
          //获取单据详情
          const res1 = await otherOrderGetDetail(vm.$route.query.billId)
          //单据赋值
          vm.form = res1.data
          //原始单据赋值
          vm.oldForm = JSON.parse(JSON.stringify(vm.form))
          console.log(res1, 'res1')
        }
      } else if (vm.$route.query.type === 'matchMaterialOrder') {
        vm.form = JSON.parse(vm.$route.query.orderInfo)
        vm.oldForm = JSON.parse(JSON.stringify(vm.form))
      } else {
        await vm.getBasicData()
        const res = await produceDeptList({ produceDeptNo: '0000' })
        if (res.rows[0]?.produceDeptId) {
          //默认生产工厂
          vm.$set(vm.form, 'produceDeptId', res.rows[0]?.produceDeptId)
          vm.changeProduceGroupChange(res.rows[0]?.produceDeptId)
        }
        vm.disabled = false

        //判断是否有以前新增时保存的数据
        if (JSON.stringify(vm.oldForm) != '{}') {
          vm.form = vm.oldForm
        }
        vm.oldForm = JSON.parse(JSON.stringify(vm.form))
      }
      vm.loading = false
    })
  },
  async beforeRouteLeave(to, from, next) {
    // 在用户离开二级路由前弹出询问框
    if (this.form.billId) {
      this.oldForm = {}
    } else {
      this.oldForm = JSON.parse(JSON.stringify(this.form))
    }
    next()
  },
  computed: {
    tableHeight() {
      return this.vivwH - this.topOperatingH - this.basicH - 62
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.topOperatingH =
        document.getElementById('topOperatingButton').clientHeight
      this.basicH = document.getElementById('basicInformation').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    limitInputlength,
    changeProduceGroupChange(v) {
      this.$set(this.form, 'produceOrderId', undefined)
      this.listProduceOrder.remoteBody = { produceDeptId: v }
    },
    handleResize() {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.topOperatingH =
        document.getElementById('topOperatingButton').clientHeight
      this.basicH = document.getElementById('basicInformation').clientHeight
    },
    unitSalePriceChange,
    //表格行hover时,显示操作加减号
    cellMouseEnter(row) {
      let eleIndex = this.form.billDetailItems.indexOf(row)
      this.form.billDetailItems[eleIndex].hoverRow = true
      this.tableCellLabel = '操作'
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave() {
      // 移除hover的事件
      for (let index = 0; index < this.form.billDetailItems.length; index++) {
        const element = this.form.billDetailItems[index]
        element['hoverRow'] = false
      }
      this.tableCellLabel = '序号'
    },
    //退出
    getQuit() {
      this.$tab.closeOpenPage({ path: "/produce/other/storeOrder" });
    },
    //新增按钮
    async handleAdd() {
      this.loading = true
      this.reset()
      const res2 = await getBillNo(170207)
      this.form.billNo = res2
      this.loading = false
    },
    //数量键盘抬起
    onkeyupUnitQty(row) {
      const unitMoney = row.unitQty * row.unitPrice
      this.$set(row, 'unitMoney', unitMoney)
    },
    //表单重置
    async reset() {
      //表单数据
      this.form = {
        billStatus: '', //单据审核状态
        billNo: null, //单据编号
        billDate: null, //单据日期
        arrivalDate: null, //到货日期
        produceOrderId: null, //生产班次
        orderStoreId: null, //订货仓库
        produceDeptId: null, //生产工厂
        billRemark: null, //备注
        //单据表格-订单明细
        billDetailItems: [{}]
      }
      this.resetForm('form')
      this.form.billDate = getNewDate()
      this.form.arrivalDate = getNewDate(1)
      const res = await produceDeptList({ produceDeptNo: '0000' })
      if (res.rows[0]?.produceDeptId) {
        //默认生产工厂
        this.$set(this.form, 'produceDeptId', res.rows[0]?.produceDeptId)
        this.changeProduceGroupChange(res.rows[0]?.produceDeptId)
      }
      const res2 = await getBillNo(170207)
      this.form.billNo = res2
    },
    //初始化页面
    async getBasicData() {
      //获取单据日期
      this.form.billDate = getNewDate()
      this.form.arrivalDate = getNewDate(1)

      //新增时获取单据编号
      const res2 = await getBillNo(170207)
      this.form.billNo = res2
    },
    //保存并新增单据
    async addBill() {
      this.loading = true
      await this.submitForm(false)
      this.loading = false
    },
    //订单明细表格合计
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        let name = ''
        if (index === 6) {
          name = 'unitQty'
        } else if (index === 8) {
          name = 'unitMoney'
          sums[index] += ' 元'
        } else {
          sums[index] = ''
          return
        }

        const values = data.filter(item => !(item[`${name}`] === undefined))
        const values2 = values.map(i => Number(i[`${name}`]))
        sums[index] = values2.reduce((prev, curr) => {
          return prev + curr
        }, 0)
      })
      return sums
    },

    //选中的商品
    getGoodS(value) {
      let rows = JSON.parse(JSON.stringify(value))

      rows.forEach(v => {
        this.$set(v, 'unitQty', '')
        this.$set(v, 'unitPrice', 0)
        this.$set(v, 'unitMoney', 0)
        v.unitPrice = v.purPrice
        v.remark = ''
        v.unitMoney = v.unitQty * v.unitPrice
        v.hoverRow = false
      })
      // this.form.billDetailItems.push(...value)
      let findIndex = rows[0].findIndex
      if (rows.length == 1 && findIndex != undefined) {
        console.log(rows[findIndex])
        this.form.billDetailItems[findIndex] = rows[0]
      } else {
        this.form.billDetailItems.push(...rows)
      }
      this.form.billDetailItems = this.form.billDetailItems.filter(
        item => item.goodsNo != '' && item.goodsNo != undefined
      )
    },

    //表格单元格回车事件
    keyup(name, event, index) {
      //判断是否输入编码或条码
      if (this.form.billDetailItems[`${index}`][`${name}`]) {
        listGoods({
          [name]: this.form.billDetailItems[`${index}`][`${name}`],
          goodsTypes: [1, 2]
        }).then(response => {
          // 判断是否精准匹配 匹配直接赋值 不匹配弹出商品框
          if (response.total === 1) {
            event.target.blur()
            //原始赋值
            response.rows[0].remark = ''
            response.rows[0].unitQty = ''
            response.rows[0].unitPrice = response.rows[0].purPrice
            response.rows[0].unitMoney = 0
            //使用vue的$set方法更新数组数据,使视图可编辑
            this.$set(this.form.billDetailItems, index, response.rows[0])
          } else {
            this.queryCode = this.form.billDetailItems[`${index}`][`${name}`]
            this.$refs.selectGoods.queryParams.goodsTypes = [1, 2]
            this.OpenGoods = true
          }
        })
      } else {
        //没输入直接弹出商品信息框
        event.target.blur()
        this.$refs.selectGoods.queryParams.goodsTypes = [1, 2]
        this.OpenGoods = true
      }
    },

    //选择商品方法
    selectGoods() {
      this.$refs.selectGoods.queryParams.goodsTypes = [1, 2]
      this.OpenGoods = true
    },
    //表格增加/减少一行方法
    row(name, index) {
      if (name === 'push') {
        this.form.billDetailItems.splice(index + 1, 0, {
          goodsNo: '',
          hoverRow: false
        })
      } else {
        if (this.form.billDetailItems.length <= 1) {
          this.form.billDetailItems = [{
            goodsNo: "",
            hoverRow: false,
          }];
        } else {
          this.form.billDetailItems.splice(index, 1);
        }
      }
    },
    //审核单据方法
    async auditBill(name) {
      try {
        await this.$confirm(`确定${name}该订单, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'success'
        })

        if (name === '审核') {
          if (this.form.billStatus != '0') {
            this.$message.error('此单据不是未审核的状态哦~')
            return
          }

          const obj = {
            billIds: [this.form.billId],
            billStatus: 2
          }
          await this.submitForm(true)
          //发送审核api
          const res = await otherOrderUpdateBillStatus(obj)
          this.form = res.data
          // 弹出提示
          this.$message.success('审核成功')
          //禁用全部输入
          this.disabled = true
        } else if (name === '反审核') {
          if (this.form.billStatus != '2') {
            this.$message.error('此单据不是已审核的状态哦~')
            return
          }
          const obj = {
            billIds: [this.form.billId],
            billStatus: 0
          }
          //发送审核api
          const res = await otherOrderUpdateBillStatus(obj)
          this.form = res.data
          // 弹出提示
          this.$message.success('反审核成功')
          //恢复全部输入
          this.disabled = false
        } else if (name === '终止') {
          if (this.form.billStatus != '2') {
            this.$message.error('此单据不是未审核的状态哦~')
            return
          }
          const obj = {
            billIds: [this.form.billId],
            billStatus: 4
          }
          //发送审核api
          const res = await otherOrderUpdateBillStatus(obj)
          this.form = res.data
          // 弹出提示
          this.$message.success('单据关闭成功')
        }
      } catch { }
    },

    /** 提交按钮 */
    async submitForm(isBool) {
      if (
        !(JSON.stringify(this.form.billDetailItems[0]) === '{}') &&
        !(this.form.billDetailItems[0].goodsId === undefined)
      ) {
        const valid = await this.$refs.form.validate()
        // this.$refs['form'].validate(async valid => {
        if (this.isSubmit) return
        this.isSubmit = true
        if (valid) {
          if (this.form.billId) {
            try {
              const res = await otherOrderUpdate(this.form)
              console.log(res, 'res')
              //重新赋值给表单，可以把id赋值
              if (isBool) {
                this.form = res.data
                this.$modal.msgSuccess('修改单据成功')
              } else {
                this.reset()
                this.$modal.msgSuccess('保存并新增单据成功')
              }
              this.isSubmit = false
            } catch (error) {
              this.isSubmit = false
            }
          } else {
            try {
              const res = await otherOrderSave(this.form)
              //重新赋值给表单，可以把id赋值
              if (isBool) {
                this.form = res.data
                this.$modal.msgSuccess('新增单据成功')
              } else {
                this.reset()
                this.$modal.msgSuccess('保存并新增单据成功')
              }
              this.isSubmit = false
            } catch (error) {
              this.isSubmit = false
            }
          }
        }
        // })
      } else {
        this.$message.error('单据明细不能为空')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
//整体盒子
.wrap {
  padding: 40px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  .headGoodsTable {
    //商品表格卡片
    .goodsMessage {
      padding: 6px 10px;
      ::v-deep .el-form-item__content {
        margin-left: 0 !important;
      }
      ::v-deep .el-form-item__error {
        position: static;
      }
    }
  }
}
::v-deep .el-form-item {
  margin: 0;
  padding: 0;
}
//输入框原生属性
/**
 * 解决el-input设置类型为number时，中文输入法光标上移问题
 **/
::v-deep .el-input__inner {
  line-height: 1px !important;
}

/**
 * 解决el-input设置类型为number时，去掉输入框后面上下箭头
 **/
::v-deep input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep .el-input__suffix-inner {
  z-index: 11;
  height: 100%;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
