<template>
  <div class="wrap">
    <TablePage v-model="options" @handleEvent="handleEvent"></TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { getDetail } from "@/api/shop/report/posHandover.js"; //销售完成比查询
import { getAllShopInfo } from "@/api/shop/base/shopInfo";
export default {
  name: "orderQuery",
  components: { TablePage },
  data() {
    return {
      payTypes: [], // 支付方式
      options: {
        summary: "",
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        getListApi: getDetail,
        body: {},
        title: "裱花完工入库查询",
        rowKey: "id",
        search: [
          {
            type: "input",
            label: "",
            model: "",
            filter: "cashierName",
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级搜索",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
          {
            label: "所属门店",
            seniorSearch: true,
            type: "local",
            model: "",
            filter: "shopId",
            option: {
              remote: getAllShopInfo,
              remoteBody: { shopName: "" },
              label: "shopName",
              value: "shopId",
              filterable: true,
              labels: [
                { title: "门店编号", label: "shopNo" },
                { title: "门店名称", label: "shopName" },
              ],
            },
          },
        ],
        columns: [
          {
            prop: "shopName",
            label: "门店名称",
            minWidth: 120,
          },
          {
            prop: "cashierNo",
            label: "收银员编号",
            minWidth: 80,
          },
          {
            prop: "cashierName",
            label: "收银员名称",
            minWidth: 80,
          },
          {
            prop: "begTime",
            label: "交班时间",
            minWidth: 130,
          },
          {
            prop: "firstTime",
            label: "首笔交易时间",
            minWidth: 130,
          },
          {
            prop: "lastTime",
            label: "末笔交易时间",
            minWidth: 130,
          },
          {
            prop: "totalPayMoney",
            label: "总交易金额",
            minWidth: 100,
          },
          {
            prop: "payTypes",
            type: "slots",
            align: "center",
            formatter: () => "",
            label: "收款方式",
            minWidth: 300,
          },
        ],
        list: [],
      },
      shopInfo: [],
      tenantInfo: [],
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "getListed":
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
