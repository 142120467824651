<template>
  <!--其他订货单 -->
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
    </TablePage>
  </div>
</template>

<script>
import TablePage from '@/components/tablePage' //表格页面组件
import {
  otherOrderList,
  otherOrderUpdateBillStatus
} from '@/api/produce/bill/storeDistribute' //其他订货单
export default {
  name: 'StoreOrder',
  components: { TablePage },
  data () {
    return {
      options: {
        mutiSelect: true, // 多选
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        getListApi: otherOrderList,
        listNo: true, // 序号
        check: [], // 选中数据
        rowKey: 'billId',
        //搜索
        search: [
          {
            label: '日期',
            type: 'datetimerange',
            model: '',
            noReset: true,
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'month',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          // {
          //   type: 'filters',
          //   isRestore: true,
          //   model: '',
          //   filters: [
          //     { filter: 'query', label: '全部' },
          //     { filter: 'billNos', label: '单据编号' },
          //     { filter: 'orderStoreNos', label: '订货仓库编号' },
          //     { filter: 'orderStoreNames', label: '订货仓库名称' },
          //     { filter: 'produceDeptNos', label: '生产部门编号' },
          //     { filter: 'produceDeptNames', label: '生产工厂' }
          //   ]
          // },
          {
            label: '到货日期',
            type: 'date',
            filter: 'arrivalDate',
            model: '',
            seniorSearch: true
          },
          this.$select({
            key: 'listProduceStore',
            option: {
              type: 'remote',
              label: '订货仓库',
              filter: 'orderStoreIds',
              seniorSearch: true,
              option: {
                multiple: true
              }
            }
          }),
          this.$select({
            key: 'listProduceDept',
            option: {
              type: 'remote',
              label: '生产工厂',
              filter: 'produceDeptIds',
              seniorSearch: true,
              option: {
                multiple: true
              }
            }
          }),
          this.$select({
            key: 'listProduceOrder',
            option: {
              type: 'remote',
              label: '生产班次',
              filter: 'produceOrderIds',
              seniorSearch: true,
              option: {
                multiple: true
              }
            }
          }),
          this.$select({
            key: 'bill_status',
            option: {
              seniorSearch: true,
              option: {
                multiple: true
              }
            }
          }),
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        //按钮
        buttons: [
          {
            click: 'add',
            label: '新增',
            icon: 'el-icon-plus',
            type: 'primary'
          },
          {
            click: 'audit',
            label: '审核',
            alertText: '确认要审核选中方案吗？',
            btnType: 'dropdown',
            type: 'success',
            other: [
              {
                click: 'reverseAudit',
                label: '反审核',
                alertText: '确认要反审核选中方案吗？'
              }
            ]
          },
          {
            label: '打印',
            type: 'primary',
            btnType: 'dropdown',
            print: {}
          },
          {
            click: 'del',
            label: '删除',
            type: 'danger',
            icon: 'el-icon-delete'
          },
          {
            click: 'stop',
            label: '終止',
            type: 'primary',
            icon: 'el-icon-remove-outline'
          },
          {
            click: 'refresh',
            right: true,
            label: '刷新',
            icon: 'el-icon-refresh',
            type: ''
          }
        ],
        //表格
        columns: [
          {
            prop: 'billNo',
            label: '单据编号',
            type: 'link',
            click: 'update',
            minWidth: 150,
            align: 'center'
          },
          {
            prop: 'billDate',
            label: '单据日期',
            minWidth: 155,
            align: 'center'
          },
          {
            prop: 'billStatusName',
            label: '单据状态',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'orderStoreNo',
            label: '订货仓库编号',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'orderStoreName',
            label: '订货仓库',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'produceDeptNo',
            label: '生产工厂编号',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'produceDeptName',
            label: '生产工厂',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'produceOrderName',
            label: '生产班次',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'billQty',
            label: '数量',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'billMoney',
            label: '金额',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'arrivalDate',
            label: '到货日期',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'billRemark',
            label: '备注',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'auditBy',
            label: '审核人',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'auditTime',
            label: '审核时间',
            minWidth: 155,
            align: 'center'
          },
          {
            prop: 'createBy',
            label: '创建人',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'createTime',
            label: '创建日期',
            minWidth: 155,
            align: 'center'
          },
          {
            prop: 'updateBy',
            label: '修改人',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'updateTime',
            label: '修改日期',
            minWidth: 155,
            align: 'center'
          }
        ],
        list: []
      }
    }
  },
  methods: {
    async handleEvent (type, row) {
      let billIds = []
      switch (type) {
        case 'add':
          this.$router.push({
            path: '/produce/other/storeOrder/storeOrderDetail'
          })
          break
        case 'update':
          this.$router.push({
            path: '/produce/other/storeOrder/storeOrderDetail',
            query: {
              billId: row.billId,
              type: 'Update'
            }
          })
          break
        case 'audit':
          this.options.check.forEach(item => {
            if (item.billStatus != 3) billIds.push(item.billId)
          })
          if (billIds.length >= 1) {
            try {
              await otherOrderUpdateBillStatus({
                billIds,
                billStatus: 2
              })
              this.$message.success('审核成功')
              this.$refs.tablePage.getList()
            } catch (err) {}
          } else {
            this.$message.error('请选择未删除的单据')
          }
          break
        case 'reverseAudit':
          this.options.check.forEach(item => {
            if (item.billStatus != 3) billIds.push(item.billId)
          })
          if (billIds.length >= 1) {
            try {
              await otherOrderUpdateBillStatus({
                billIds,
                billStatus: 0
              })
              this.$message.success('反审核成功')
              this.$refs.tablePage.getList()
            } catch (err) {}
          } else {
            this.$message.error('请选择未删除的单据')
          }
          break
        case 'del':
          this.options.check.forEach(item => {
            if (item.billStatus == 0) billIds.push(item.billId)
          })
          if (billIds.length >= 1) {
            try {
              await otherOrderUpdateBillStatus({
                billIds,
                billStatus: 3
              })
              this.$message.success('删除成功')
              this.$nextTick(() => {
                this.options.check = [];
              });
              this.$refs.tablePage.getList()
            } catch (err) {}
          } else {
            this.$message.error('请选择未审核的单据')
          }
          break
        case 'stop':
          this.options.check.forEach(item => {
            if (item.billStatus == 0) billIds.push(item.billId)
          })
          if (billIds.length >= 1) {
            try {
              await otherOrderUpdateBillStatus({
                billIds,
                billStatus: 4
              })
              this.$message.success('关闭成功')
              this.$refs.tablePage.getList()
            } catch (err) {}
          } else {
            this.$message.error('请选择未审核的单据')
          }
          break
        default:
          break
      }
    }
  }
}
</script>
